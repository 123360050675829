import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import {
  Box,
  Button,
  Form,
  Message,
  Spacing,
  TextField
} from 'groundkeeper-component-library';

export default function LoginForm(props) {
  const intl = useIntl();
  const router = useRouter();

  return (
    <Form
      onSubmit={props.handleSubmit}
      initialValues={{ email: '', password: '' }}
      render={({ form, handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit} data-testid="loginForm">
          <TextField
            // @ts-ignore: no-typescript-component
            id="email"
            requiredWithoutAsterisk
            isEmail
            name="email"
            fullWidth
            label={intl.formatMessage({
              id: 'email',
              defaultMessage: 'E-Mail'
            })}
          />
          <Spacing y={2} />
          <TextField
            // @ts-ignore: no-typescript-component
            id="password"
            requiredWithoutAsterisk
            fullWidth
            name="password"
            type="password"
            label={intl.formatMessage({
              id: 'password',
              defaultMessage: 'Password'
            })}
          />
          {submitError && (
            <>
              <Spacing y={1} />
              <Message type="error">{submitError}</Message>
            </>
          )}
          <Spacing y={2} />
          <Box display="flex" justifyContent="flex-end">
            {/* @ts-ignore: no-typescript-component */}
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              endIcon="arrow-right"
              lib="fa"
              disabled={
                submitting ||
                (form.getState().hasValidationErrors &&
                  form.getState().submitFailed)
              }
            >
              {intl.formatMessage({ id: 'login', defaultMessage: 'Login' })}
            </Button>
          </Box>
        </form>
      )}
    />
  );
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};
