import React from 'react';
import {
  Breadcrumb as GKBreadcrumb,
  DefaultTheme,
  makeStyles,
  Icon
} from 'groundkeeper-component-library';
import LayoutFullWidth from './LayoutFullwidth';

const useStyles = makeStyles((theme: DefaultTheme) => ({
  wrapper: {
    background: theme.color.background.grey,
    padding: `.46rem 0`
  },
  breadcrumb: {
    maxWidth: '61.25rem',
    margin: '0 auto',
    fontSize: theme.font.size.small,
    '& .text': {
      fontSize: theme.font.size.small
    },
    '& a': {
      color: theme.color.text.light
    },
    '& a:hover': {
      textDecoration: 'underline'
    },
    '& .MuiBreadcrumbs-separator': {
      margin: '0 .2rem'
    },
    [theme.mediaQueries.medium]: {
      padding: '0 40px'
    },
    [theme.mediaQueries.small]: {
      padding: '0 1.25rem'
    }
  }
}));

function Breadcrumb({ children }) {
  const classes = useStyles();

  return (
    <LayoutFullWidth className={classes.wrapper}>
      <GKBreadcrumb
        // @ts-ignore: no-typescript-component
        separator={<Icon name="arrow-right" />}
        className={classes.breadcrumb}
      >
        {children}
      </GKBreadcrumb>
    </LayoutFullWidth>
  );
}

export default Breadcrumb;
