import React, { ReactNode } from 'react';
import { makeStyles } from 'groundkeeper-component-library';

import classNames from 'shared/SharedJS/src/utils/react/classNames';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    position: 'relative',
    left: '50%',
    right: '50%',
    width: '100vw',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    paddingLeft: 0,
    paddingRight: 0
  }
}));

function LayoutFullWidth({
  children,
  className
}: {
  children: ReactNode;
  className: string;
}) {
  const classes = useStyles();

  return (
    <div className={classNames([classes.fullWidth, className])}>{children}</div>
  );
}

export default LayoutFullWidth;
